body {
  background: #000;
}

:global {
  .w-100 {
    width: 100%;
  }

  .b-none {
    border-style: none;
  }
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: calc(100vh + 60px);
}

.fastChannelPage {
  color: #fff;
  flex-grow: 1;

  .container {
    aspect-ratio: 2.435;
    display: grid;

    @media
      (min-width: 1024px),
      (max-width: 1024px) and (orientation: landscape) {
      grid-template-columns: 1fr 0.4fr;
      width: 100%;
    }

    @media (min-width: 2080px) {
      max-width: 1800px;
      margin: 0 auto;
      width: 100%;
    }

    .playerContainer {
      height: 100%;
      max-height: 985px;
      position: sticky;
      top: 60px;
      width: 100%;
      z-index: 1;

      @media (min-width: 1024px), (max-width: 1024px) and (orientation: landscape) {
        position: initial;
      }
    }

    .chromeless {
      top: 0;
    }

    .descAndNextEpisode {
      align-items: start;
      display: grid;
      flex-grow: 1;
      gap: 60px;
      grid-column: 1 / -1;
      grid-template-columns: 700px 1fr;
      margin: 30px;

      @media (max-width: 1024px) {
        gap: unset;
        grid-template-columns: unset;
        margin: 15px 30px;
      }

      .text {
        color: #fff;
        font-family: FoundersGroteskCond, 'Arial Narrow', Arial, sans-serif;
        font-weight: 600;
        margin: 0;
      }

      .description {
        display: grid;
        font-size: 21px;
        gap: 8px 10px;
        grid-template-columns: auto 1fr;

        .image {
          height: 100%;
          max-height: 18px;
          max-width: 100%;
        }

        .text {
          grid-column: 1 / -1;
        }
      }

      .nextEpisode {
        border-left: 1px solid #999;
        border-top: none;
        display: initial;
        font-size: 18px;
        margin: 0;
        padding: 0 0 3px 10px;

        @media (max-width: 1024px) {
          border-left: unset;
          border-top: 1px solid #fff;
          display: grid;
          grid-template-columns: auto 1fr;
          margin: 15px 0 10px;
          padding: 15px 0 0;
          white-space: initial;

          .text:first-child::after {
            content: ':';
            margin-right: 10px;
          }
        }
      }
    }

    .channelSelectorContainer {
      position: relative;
      z-index: 0;

      @media (min-width: 1024px), (max-width: 1024px) and (orientation: landscape) {
        grid-column: 2;
        grid-row: 1;
        overflow-y: auto;
      }

      .scrollShadow {
        bottom: 0;
        position: sticky;
        width: 100%;
        z-index: 1;

        @media (min-width: 1024px), (max-width: 1024px) and (orientation: landscape) {
          &::after {
            background:
              linear-gradient(
                0deg,
                rgb(0, 0, 0),
                rgba(0, 0, 0, 0.7) 30%,
                rgba(0, 0, 0, 0.5) 50%,
                rgba(0, 0, 0, 0.3) 70%,
                rgba(0, 0, 0, 0) 100%
              );
            content: '';
            display: block;
            height: 38px;
            pointer-events: none;
          }
        }
      }
    }

    .hideScrollShadow {
      display: none;
    }
  }
}
