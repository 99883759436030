.fastChannelSelector {
  display: flex;
  flex-direction: column;
  width: 100%;

  > button {
    display: flex;
    justify-content: left;
    background: #202831;
    border: 1px solid #000;
    padding: 22px;
    max-height: 62px;

    &:hover {
      cursor: pointer;
      background: #4b4b4b;
    }

    &.activeChannel {
      background: #2f61ff;
    }

    &:first-child {
      border-top: none;
    }

    &:last-child {
      border-bottom: none;
    }

    img {
      width: 100%;
    }

    @media (max-width: 760px) {
      img {
        width: unset;
      }
    }
  }
}
